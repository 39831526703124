<template>
  <page-header-wrapper :title="false">
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="编号">
                <a-input placeholder="请输入编号" v-model="queryParam.keyId" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="网站名称">
                <a-input placeholder="请输入网站名称" v-model="queryParam.linkName" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetSearchForm()">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd">添加</a-button>
      </div>
      <s-table
        ref="table"
        size="default"
        rowKey="keyId"
        :columns="columns"
        :data="loadData"
      >
        <span slot="linkIcon" slot-scope="text, record">
          <a @click="handlePreview(record.linkIcon, record.title)">
            <img :src="record.linkIcon" :height="40" />
          </a>
        </span>
        <span slot="isEnabled" slot-scope="text">
          <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
        </span>
        <span slot="remark" slot-scope="text">
          <ellipsis :length="24" tooltip>{{ text }}</ellipsis>
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a @click="setLinkStatusApi(record.keyId, 0)" v-if="record.isEnabled === 1">禁用</a>
          <a @click="setLinkStatusApi(record.keyId, 1)" v-else>启用</a>
        </span>
      </s-table>
      <website-info
        ref="websiteinfo"
        :visible="visible"
        @cancel="linkCancel"
        @ok="linkOk"
      ></website-info>
      <a-modal :visible="previewInfo.previewVisible" :title="previewInfo.previewTitle" :footer="null" @cancel="cancelPreview">
        <img alt="example" style="width: 100%" :src="previewInfo.previewImage" />
      </a-modal>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { linkitemList, setLinkStatus } from '@/api/linkitem'
import WebsiteInfo from '@/views/link/components/WebsiteInfo'
import { Modal } from 'ant-design-vue'
const columns = [
  {
    title: '编号',
    dataIndex: 'keyId',
    width: '100px'
  },
  {
    title: '网站名称',
    dataIndex: 'linkName'
  },
  {
    title: '网址',
    dataIndex: 'linkTarget'
  },
  {
    title: '图标',
    dataIndex: 'linkIcon',
    scopedSlots: { customRender: 'linkIcon' }
  },
  {
    title: '备注',
    dataIndex: 'remark',
    scopedSlots: { customRender: 'remark' }
  },
  {
    title: '状态',
    dataIndex: 'isEnabled',
    scopedSlots: { customRender: 'isEnabled' }
  },
  {
    title: '添加时间',
    dataIndex: 'createTime'
  },
  {
    title: '操作',
    width: '140px',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

const statusMap = {
  0: {
    status: 'default',
    text: '禁用'
  },
  1: {
    status: 'success',
    text: '启用'
  }
}

export default {
  name: 'WebsiteList',
  components: {
    STable,
    Ellipsis,
    WebsiteInfo
  },
  data () {
    this.columns = columns
    return {
      description: '',
      visible: false,
      form: this.$form.createForm(this, { name: 'addPhoneFrom' }),
      mdl: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },

      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        keyId: '',
        linkName: '',
        linkType: 0
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        return linkitemList(requestParameters)
          .then(res => {
            return res.result
          })
      },
      checkParams: {
        linkNameStatus: 'success',
        linkNameStatusMsg: null,
        isLinkName: false,
        linkTargetStatus: 'success',
        linkTargetStatusMsg: null,
        isLinkTarget: false
      },
      dataParams: {
        communityName: ''
      },
      previewInfo: {
        previewVisible: false,
        previewTitle: '',
        previewImage: ''
      }
    }
  },
  filters: {
    statusFilter (type) {
      return statusMap[type].text
    },
    statusTypeFilter (type) {
      return statusMap[type].status
    }
  },
  created () {
  },
  watch: {
  },
  methods: {
    resetSearchForm () {
      this.queryParam = {
        keyId: '',
        linkName: '',
        linkType: 0
      }
    },
    handleAdd () {
      this.mdl = {
        keyId: 0,
        id: '',
        linkName: '',
        linkTarget: '',
        linkType: 0,
        linkIcon: '',
        linkOrder: 0,
        isEnabled: 1,
        remark: ''
      }
      this.visible = true
      this.$refs.websiteinfo.initialData(this.mdl)
    },
    handleEdit (record) {
      this.mdl = Object.assign({}, record)
      this.visible = true
      this.$refs.websiteinfo.initialData(this.mdl)
    },
    handleOk () {
      // 新增/修改 成功时，重载列表
      this.$refs.table.refresh()
    },
    linkOk () {
      this.visible = false
      this.handleOk()
    },
    linkCancel (e) {
      this.mdl = {}
      console.log('handleCancel', e)
      this.visible = false
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    setLinkStatusApi (keyId, isEnabled) {
      const _this = this
      var title = (isEnabled === 0) ? '禁用服务' : '启用服务'
      var content = (isEnabled === 0) ? '确认要禁用该服务吗？' : '确认要启用该服务吗？'
      Modal.confirm({
        title: title,
        content: content,
        onOk: () => {
          setLinkStatus({ keyId: keyId, isEnabled: isEnabled }).then((res) => {
            if (res.errorCode === 0) {
              _this.$message.info(res.errorMsg)
              _this.$refs.table.refresh()
            } else {
              _this.$message.error(res.errorMsg)
            }
          }).catch((err) => {
            _this.$message.error('系统错误')
            console.log(err)
          })
        },
        onCancel () {
          console.log('onCancel')
        }
      })
    },
    handlePreview (url, name) {
      this.previewInfo.previewImage = url
      this.previewInfo.previewTitle = name
      this.previewInfo.previewVisible = true
    },
    cancelPreview (e) {
      console.log(e)
      this.previewInfo.previewVisible = false
      this.previewInfo.previewImage = ''
      this.previewInfo.previewTitle = ''
    }
  }
}
</script>
